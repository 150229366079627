const colorVariants = {
	blue: "bg-jig-blue",
	red: "bg-jig-red",
	green: "bg-jig-green",
	brown: "bg-jig-brown",
	rose: "bg-jig-rose",
	white: "bg-jig-white",
	yellow: "bg-jig-yellow",
}

export default colorVariants
