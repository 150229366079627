import React from "react"

export default function MoreIcon() {
	return (
		<div role="button" className="flex items-center justify-center w-[24px] h-[24px] rounded-[5px]">
			<svg role="graphics-symbol" viewBox="0 0 13 3" className="w-[14px] h-[14px] block">
				<g>
					<path d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"></path>
					<path d="M8,1.5A1.5,1.5,0,1,1,6.5,0,1.5,1.5,0,0,1,8,1.5Z"></path>
					<path d="M13,1.5A1.5,1.5,0,1,1,11.5,0,1.5,1.5,0,0,1,13,1.5Z"></path>
				</g>
			</svg>
		</div>
	)
}
