const gaps = {
	audio: "py-3",
	text: "py-1",
	heading: "py-2",
	title: "py-3",
	image: "py-3",
	question: "py-3",
	message: "py-3",
	profile: "py-3",
	"carousel-tile": "py-3",
	"page-tile": "py-3",
	"block-selector": "py-1",
	"carousel-configurer": "py-1",
	"page-configurer": "py-1",
	"image-configurer": "py-1",
	"reel-configurer": "py-1",
	"audio-configurer": "py-1",
}

export default gaps
