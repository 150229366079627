import React from "react"

export default function Logo() {
	return (
		<svg width="64" height="64" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="3" width="23" height="23" rx="5" fill="#F672D1" />
			<rect y="26" width="32" height="5" rx="2.5" fill="black" />
		</svg>
	)
}
