const textEditingVariants = {
	text: {
		lineHeight: "h-min-[1.7rem] h-[1.7rem]",
		class: "text-paragraph",
	},
	heading: {
		lineHeight: "h-min-[2rem] h-[2rem]",
		class: "text-heading",
	},
	title: {
		lineHeight: "h-min-[2.5rem] h-[2.5rem]",
		class: "text-title",
	},
}

export default textEditingVariants
