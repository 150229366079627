import React from "react"

export default function PositionIcon() {
	return (
		<svg height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M16.75 4C16.75 5.51878 15.5188 6.75 14 6.75C12.4812 6.75 11.25 5.51878 11.25 4C11.25 2.48122 12.4812 1.25 14 1.25C15.5188 1.25 16.75 2.48122 16.75 4ZM14 5.25C14.6904 5.25 15.25 4.69036 15.25 4C15.25 3.30964 14.6904 2.75 14 2.75C13.3096 2.75 12.75 3.30964 12.75 4C12.75 4.69036 13.3096 5.25 14 5.25Z" fill="#1C274C" />
			<path fillRule="evenodd" clipRule="evenodd" d="M12 14.75C10.4812 14.75 9.25 13.5188 9.25 12C9.25 10.4812 10.4812 9.25 12 9.25C13.5188 9.25 14.75 10.4812 14.75 12C14.75 13.5188 13.5188 14.75 12 14.75ZM10.75 12C10.75 12.6904 11.3096 13.25 12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75C11.3096 10.75 10.75 11.3096 10.75 12Z" fill="#1C274C" />
			<path fillRule="evenodd" clipRule="evenodd" d="M10 22.75C8.48122 22.75 7.25 21.5188 7.25 20C7.25 18.4812 8.48122 17.25 10 17.25C11.5188 17.25 12.75 18.4812 12.75 20C12.75 21.5188 11.5188 22.75 10 22.75ZM8.75 20C8.75 20.6904 9.30964 21.25 10 21.25C10.6904 21.25 11.25 20.6904 11.25 20C11.25 19.3096 10.6904 18.75 10 18.75C9.30964 18.75 8.75 19.3096 8.75 20Z" fill="#1C274C" />
			<path d="M15.25 12C15.25 11.5858 15.5858 11.25 16 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H16C15.5858 12.75 15.25 12.4142 15.25 12Z" fill="#1C274C" />
			<path d="M14 19.25C13.5858 19.25 13.25 19.5858 13.25 20C13.25 20.4142 13.5858 20.75 14 20.75H19C19.4142 20.75 19.75 20.4142 19.75 20C19.75 19.5858 19.4142 19.25 19 19.25H14Z" fill="#1C274C" />
			<path d="M10.75 4C10.75 3.58579 10.4142 3.25 10 3.25L5 3.25C4.58579 3.25 4.25 3.58579 4.25 4C4.25 4.41421 4.58579 4.75 5 4.75L10 4.75C10.4142 4.75 10.75 4.41421 10.75 4Z" fill="#1C274C" />
			<path d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H8C8.41421 12.75 8.75 12.4142 8.75 12C8.75 11.5858 8.41421 11.25 8 11.25H5Z" fill="#1C274C" />
			<path d="M4.25 20C4.25 19.5858 4.58579 19.25 5 19.25H6C6.41421 19.25 6.75 19.5858 6.75 20C6.75 20.4142 6.41421 20.75 6 20.75H5C4.58579 20.75 4.25 20.4142 4.25 20Z" fill="#1C274C" />
			<path d="M19 3.25C19.4142 3.25 19.75 3.58579 19.75 4C19.75 4.41421 19.4142 4.75 19 4.75H18C17.5858 4.75 17.25 4.41421 17.25 4C17.25 3.58579 17.5858 3.25 18 3.25H19Z" fill="#1C274C" />
		</svg>
	)
}
