import React from "react"

export default function NavigationArrowIcon() {
	return (
		<svg height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M17.3864 2.92637C16.0864 2.75159 14.3782 2.75 12 2.75C9.62177 2.75 7.91356 2.75159 6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.42514 4.56445 3.09825 5.33517 2.92637 6.61358C2.75159 7.91356 2.75 9.62177 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62177 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12C21.25 9.62177 21.2484 7.91356 21.0736 6.61358C20.9018 5.33517 20.5749 4.56445 20.0052 3.9948C19.4355 3.42514 18.6648 3.09825 17.3864 2.92637ZM17.5863 1.43975C19.031 1.63399 20.1711 2.03933 21.0659 2.93414C21.9607 3.82895 22.366 4.96897 22.5603 6.41371C22.75 7.82519 22.75 9.63423 22.75 11.9426V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574V11.9426C1.24999 9.63423 1.24998 7.82519 1.43975 6.41371C1.63399 4.96897 2.03933 3.82895 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25H12.0574C14.3658 1.24999 16.1748 1.24998 17.5863 1.43975Z" fill="#1C274C" />
			<path fillRule="evenodd" clipRule="evenodd" d="M15.804 7.06497L15.5357 6.36463C16.8766 5.85082 18.1489 7.12317 17.6351 8.4641L14.123 17.6299C13.8465 18.3516 13.1899 18.7344 12.5484 18.7493C11.8988 18.7644 11.2097 18.3951 10.9741 17.6197L9.91755 14.1427L10.5834 13.9404L9.91755 14.1427C9.91226 14.1253 9.90413 14.1131 9.89537 14.1044C9.88661 14.0956 9.87441 14.0875 9.857 14.0822L6.38003 13.0257C5.60468 12.7901 5.23538 12.1009 5.25044 11.4514C5.26532 10.8098 5.64815 10.1533 6.36987 9.87672L15.5357 6.36463L15.804 7.06497ZM16.1707 7.75232C16.1543 7.74876 16.1229 7.74597 16.0724 7.76532L6.90658 11.2774C6.79785 11.3191 6.75195 11.4039 6.75004 11.4862C6.74909 11.5272 6.76027 11.5518 6.76671 11.5614C6.76679 11.5615 6.76688 11.5617 6.76697 11.5618C6.7698 11.5661 6.77826 11.579 6.81613 11.5905L10.2931 12.647L10.0751 13.3646L10.2931 12.647C10.803 12.8019 11.1978 13.1968 11.3528 13.7066L12.4093 17.1836C12.4208 17.2215 12.4336 17.2299 12.4379 17.2328L12.4383 17.233C12.448 17.2395 12.4725 17.2507 12.5136 17.2497C12.5959 17.2478 12.6807 17.2019 12.7223 17.0932L16.2344 7.92739C16.2538 7.87689 16.251 7.84541 16.2474 7.82907C16.2433 7.81013 16.2339 7.79318 16.2203 7.7795C16.2066 7.76582 16.1896 7.75645 16.1707 7.75232Z" fill="#1C274C" />
		</svg>
	)
}
